<template>
    <v-container fluid>
        <v-card flat class="mr-3">
            <v-card-title>Open to buy</v-card-title>
            <v-card-subtitle>Configure the optimum turn per item and size</v-card-subtitle>
            <v-card-text class="mt-5">
                <v-row class="mb-2">
                    <v-col cols="6">
                        <v-btn @click="bulkDialog = true" color="primary" depressed :disabled="selected.length < 2">
                            Bulk edit
                        </v-btn>
                    </v-col>
                    <v-col cols="6" class="pt-0">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Filter" single-line hide-details></v-text-field>
                    </v-col>
                </v-row>
                
                <v-data-table v-model="selected" :headers="headers" :items="items" :footer-props="paginationOptions" :search="search" multi-sort
                    item-key="id" :options="pagination" :loading="loading" @pagination="onPagination" show-select>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="editDialog" max-width="500px">
            <v-card>
            <v-card-title>
              <span class="text-h5">Edit item</span>
            </v-card-title>

            <v-card-text v-if="editedItem != null">
                <table>
                    <tr>
                        <td><strong>Style</strong>:</td>
                        <td>{{ editedItem.style }}</td>
                    </tr>
                    <tr>
                        <td><strong>Size</strong>:</td>
                        <td>{{ editedItem.size }}</td>
                    </tr>
                    <tr>
                        <td class="pr-3"><strong>Description</strong>:</td>
                        <td>{{ editedItem.description }}</td>
                    </tr>
                </table>

                <v-text-field v-model="editedItem.opt_turn" label="Opt. turn" outlined class="mt-5" hide-details></v-text-field>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDialog">Cancel</v-btn>
              <v-btn color="grey darken-4" class="white--text" depressed @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="bulkDialog" max-width="500px">
            <v-card>
            <v-card-title>
              <span class="text-h5">Bulk edit</span>
            </v-card-title>

            <v-card-text>
                <table>
                    <tr>
                        <td><strong>Selected items</strong>:</td>
                        <td>{{ selected.length }}</td>
                    </tr>
                </table>

                <v-form ref="form">
                    <v-text-field v-model="optTurns" label="Opt. turn" outlined class="mt-5" hide-details v-mask="'###'"
                        :rules="[(v) => !!v || 'required']"></v-text-field>
                </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDialog">Cancel</v-btn>
              <v-btn color="grey darken-4" class="white--text" depressed @click="saveBulk">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mask } from 'vue-the-mask';

export default {
    data: () => ({
        headers         : [
            { text: 'Style', value: 'style', sortable: true },
            { text: 'Size', value: 'size', sortable: true },
            { text: 'Opt. turns', value: 'opt_turn', sortable: true },
            { text: 'Description', value: 'description', sortable: true },
            { text: 'Actions', value: 'actions', sortable: false, align: 'center' }
        ],
        pagination      : {
            sortBy: ['style','size']
        },
        paginationOptions: { itemsPerPageOptions: [ 25, 50, -1 ] },
        loading         : false,
        search          : '',

        editDialog      : false,
        bulkDialog      : false,
        editedIndex     : null,
        editedItem      : null,

        selected        : [],
        optTurns        : '',
    }),
    computed: {
        ...mapGetters({
            items       : 'items/items',
            hasMore     : 'items/hasMore',
            needRefresh : 'items/needRefresh'
        }),
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            loadItems   : 'items/loadItems',
            saveItem    : 'items/saveItem',
            saveItems   : 'items/saveItems',
        }),
        async init() {
            try {
                if( !this.needRefresh )
                    return; 

                this.loading = true;
                await this.loadItems();
                this.loading = false;
            }
            catch(error) {
                this.loading = false;
            }
        },
        onPagination(v) {
            if( v.pageCount != v.page || this.search != '' ) {
                return;
            }

            this.loadMore();
        },
        async loadMore() {
            try {
                if( !this.hasMore )
                    return;
                
                this.loading = true;
                await this.loadItems();
                this.loading = false;
            }
            catch(error) {
                this.loading = false;
            }
        },
        editItem( item ) {
            this.editedIndex = this.items.indexOf( item );
            this.editedItem = Object.assign( {}, item );
            this.editDialog = true;
        },
        async save() {
            // save opt turn
            try {
                await this.saveItem({
                    productId   : this.editedItem.productId,
                    size        : this.editedItem.size,
                    optTurns    : this.editedItem.opt_turn,
                    ix          : this.editedIndex
                });

                this.closeDialog();
            }
            catch(error) {
                if( error !== false ) {
                    this.mxShowMessage( error, 'error' );
                }
            }
        },
        async saveBulk() {
            if( !this.$refs.form.validate() )
                return;
            
            try {
                await this.saveItems({
                    items       : this.selected,
                    optTurns    : this.optTurns
                });

                this.closeDialog();
                this.selected = [];
            }
            catch(error) {
                if( error !== false ) {
                    this.mxShowMessage( error, 'error' );
                }
            }
        },
        closeDialog() {
            this.editDialog = false;
            this.bulkDialog = false;
            this.editedItem = null;
            this.editedIndex = null;
            this.optTurns = 5;
        }
    },
    directives: {
        mask
    }
}
</script>

<style lang="scss" scoped>

</style>